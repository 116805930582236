import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
// import CardList from '@components/CardList'
// import Divider from '@components/Divider'
import pageContextProvider from '@helpers/pageContextProvider'
import Seo from '@widgets/Seo'
export default function about({...props}) {
    // const { pageContext: { services = {} } = {} } = props
  return (
    <Layout {...props}>
        <Seo title='Milestone Services| About  ' />
    <div>about</div>
    </Layout>
  )
}
